<template>
  <form @submit.prevent="linkReferrer">
    <modal-card title="Manually link referrer" :processing="isProcessing">
      <loading v-if="isLoading" />
      <template v-else-if="hasReferral">
        <b-message type="is-danger">
          <p>This user already has a referral!</p>
        </b-message>
      </template>
      <template v-else>
        <b-message type="is-dark" class="is-size-7">
          Here you can manually link this user to a referrer (aka affiliate).
          Please be confident this referrer was the true acquisition source – as
          once set, they'll receive commission on past and future payments.
        </b-message>
        <b-field label="Referrer ID*">
          <b-input
            ref="referrerId"
            v-model="form.referrerId"
            :disabled="isProcessing"
          />
        </b-field>
        <div>
          <b-checkbox v-model="form.backdateCommission"
            >Allocate commission retrospectively</b-checkbox
          >
        </div>
        <button
          slot="footer"
          :class="{ 'is-loading': isProcessing }"
          :disabled="!isValid || isProcessing"
          type="submit"
          class="button is-success"
        >
          Link referrer
        </button>
      </template>
    </modal-card>
  </form>
</template>

<script>
import { doc, getDoc } from "@firebase/firestore";
export default {
  name: "LinkReferrerModal",
  props: {
    userId: { type: String, required: true }
  },
  data() {
    return {
      isLoading: true,
      isProcessing: false,
      referral: null,
      form: {
        userId: this.userId,
        referrerId: null,
        backdateCommission: true
      }
    };
  },
  computed: {
    hasReferral() {
      return !this.$_.isEmpty(this.referral);
    },
    isValid() {
      if (this.$_.isEmpty(this.form.referrerId)) return false;
      if (this.$_.isEmpty(this.form.userId)) return false;
      if (!this.$_.isBoolean(this.form.backdateCommission)) return false;
      return true;
    }
  },
  async created() {
    await this.getReferral();
    this.isLoading = false;
    if (!this.hasReferral)
      this.$nextTick(() => {
        this.$refs.referrerId.$el.querySelector("input").focus();
      });
  },
  methods: {
    async getReferral() {
      const referralRef = doc(this.$firestore, "referrals", this.userId);
      const referral = await getDoc(referralRef);
      if (referral.exists()) this.referral = referral.data();
      return referral;
    },
    linkReferrer() {
      if (!this.isValid) return;
      this.isProcessing = true;
      this.$store
        .dispatch("affiliate/linkReferrer", this.form)
        .then(result => {
          this.$emit("close");
          this.$toast.open({
            message: result.message
          });
        })
        .catch(error => {
          this.$toast.open({
            message: error,
            type: "is-danger"
          });
        })
        .then(() => {
          this.isProcessing = false;
        });
    }
  }
};
</script>
